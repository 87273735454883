import Rails from "@rails/ujs"

// document.addEventListener('turbolinks:load', () => {
//   if (window.location.href.includes("/users/sign_up")) {

//     var sel = document.getElementById('user_organization_id');
//     $("#user_role").prop("disabled", true);

//     sel.addEventListener('change', function(event) {

//       Rails.ajax({
//         url: "/organizations/" + sel.value + ".json",
//         type: "GET",
//         success: function(data) {
//           // console.log(data);
//           $("#user_role").prop("disabled", false);
//           $("#user_role").empty();
//           var permittes = data["permitted"];
//           var i;
//           for (i = 0; i < permittes.length; i++) {
//             console.log(permittes[i]);
//             $("#user_role").append('<option value="' + permittes[i][0] + '">'  + data["permitted"][i][1] + '</option>');
//           }
//         }
//       });
//       });
//     }
//   });
