// application.js

import Rails from "@rails/ujs"
Rails.start()

window.Rails = Rails  // Exponha o Rails globalmente

import Turbolinks from "turbolinks"
Turbolinks.start()

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

require("channels")

// Import additional packs if needed
require("packs/form")
require("packs/devise")

// External imports
import "bootstrap";
